
<template>
  <div>
    <template
      v-if="list.length > 0"
    >
      <div class="application-components-swiper-wrapper">
        <swiper
          v-if="mySwiperLoad"
          class="application-components-swiper"
          :options="swiperOptions"  
        >
          <template
            v-for="(item, index) in list"
          >
            <swiper-slide
              :key="index"
              class="application-slide"
              @click.native="imgClick(index)"
            > 
              <div
                class="application-slide-img"
              >
                <img
                  :src="item.fullLink"
                />
              </div>
            </swiper-slide>
          </template>
        </swiper>
        <div class="application-swiper-navigation">
          <div class="application-swiper-prev">
            <img src="@/assets/image/about/navigation.png" />
          </div>
          <div class="application-swiper-next">
            <img src="@/assets/image/about/navigation.png" />
          </div>
        </div>
      </div>
      <div class="application-components-swiper-wrapper">
        <swiper
          ref="mySwiper"
          class="application-components-sec-swiper"
          :options="swiperOptionsSec"
          @ready="swiperLoad"
        >
          <template
            v-for="(item, index) in list"
          >
            <swiper-slide
              :key="index"
              class="application-sec-slide"
            > 
              <div
                class="application-slide-sec-img"
              >
                <img
                  :src="item.fullLink"
                />
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
      <img-prew-view
        v-if="imgPrewShow"
        :url-list="list.map(ele => ele.fullLink)"
        :initial-index="imgActive"
        :on-close="onclose"
      />
    </template>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import ImgPrewView from '@/components/image/image-viewer.vue'
export default {
  name: 'ApplicationComponsntsSwiper',
  components: {
    Swiper,
    SwiperSlide,
    ImgPrewView
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      imgPrewShow: false,
      imgActive: 0,
      mySwiperLoad: false,
      swiper: null,
      swiperOptions: {},
      swiperOptionsSec: {
        spaceBetween: 20,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
      }
    }
  },
  methods: {
    swiperLoad() {
      this.swiper = this.$refs.mySwiper.$swiper
      this.swiperOptions.thumbs  = this.swiper 
      this.swiperOptions = {
        effect: "fade",
        navigation: {
          nextEl: ".application-swiper-next",
          prevEl: ".application-swiper-prev"
        },
        thumbs: {
          swiper: this.swiper,
        }
      },
      this.mySwiperLoad = true
    },
    imgClick(index) {
      this.imgActive = index
      this.imgPrewShow = true
      document.body.style.overflow='hidden'
    },
    onclose() {
      this.imgPrewShow = false
      document.body.style.overflow='auto'
    }
  }
}
</script>

<style lang="scss" scoped>
.application-components-swiper-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  .application-swiper-navigation {
    width: calc(100% + 100px);
    display: flex;
    justify-content: space-between;
    height: 25px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: -50px;
    & > div {
      cursor: pointer;
      &.application-swiper-next {
        transform: rotate(180deg);
      }
    }
  }
  .application-components-swiper {
    width: 100%;
    height: 657px;
    .application-slide-img {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;
      align-items: center;
      margin-bottom: 20px;
      .el-image {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
      }
    }
  }
  .application-components-sec-swiper {
    max-width: 660px;
    margin: 20px auto;
    height: 80px;
    overflow: hidden;
    // display: flex;
    // justify-content: center;
    // ::v-deep .swiper-wrapper {
    //   justify-content: center;
    // }
    .application-sec-slide {
      width: 150px;
      height: 80px;
      overflow: hidden;
      opacity: 0.5;
      transition: opacity 0.3s;
      &.swiper-slide-thumb-active {
        opacity: 1;
        transition: opacity 0.3s;
      }
      .application-slide-sec-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80px;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>