<template>
  <div class="application-components-wrapper">
    <div class="title">
      {{ $t(prop).title }}
    </div>
    <application-swiper
      :list="computedList"
    />
    <div class="application-components-home">
      <!-- <div class="title">
        {{ $t(prop).h2}}
      </div> -->
      <div class="text">
        {{ $t(prop).des}}
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationSwiper from './swiper/index.vue'
export default {
  name: 'applicationComponentsPublic',
  components: {
    ApplicationSwiper
  },
  props: {
    prop: {
      type: String,
      default: 'applicationHomeObject'
    },
    identification: {
      type: String,
      default: '应用案例-家庭'
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isLoad: false,
      mySwiperLoad: false,
      swiper: null,
      swiperOptions: {},
      swiperOptionsSec: {
        spaceBetween: 20,
        slidesPerView: 'auto',
        freeMode: true,
        watchSlidesProgress: true,
      }
    }
  },

  computed: {
    computedList() {
      return this.list.filter(e => e.identification === this.identification).map(ele => {
        const result = ele
        result.fullLink =  process.env.VUE_APP_API + ele.path
        return result
      })
    }
  },
  methods: {
    swiperLoad() {
      this.swiper = this.$refs.mySwiper.$swiper
      this.swiperOptions.thumbs  = this.swiper 
      this.swiperOptions = {
        effect: "fade",
        navigation: {
          nextEl: ".application-swiper-next",
          prevEl: ".application-swiper-prev"
        },
        thumbs: {
          swiper: this.swiper,
        }
      },
      this.mySwiperLoad = true
    }
  }
}
</script>

<style lang="scss" scoped>
.application-components-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  .title {
    width: 100%;
    line-height: 54px;
    text-align: center;
    color: rgba(34, 34, 34, 1);
    font-size: 42px;
    padding: 80px 0;
    font-weight: bold;
  }
  .application-components-home {
    .title {
      margin: 40px 0;
      font-size: 32px;
      padding: 0;
    }
    .text {
      margin: 70px 0;
      color: rgba(51, 51, 51, 1);
      font-size: 18px;
      margin-bottom: 200px;
      line-height: 32px;
    }
  }
}
</style>